import axios from "axios";
import { ElMessage } from "element-plus";
import cookie from "@/utils/cookie";
import { encrypt, decrypt } from "./aes.js";
const instance = axios.create({
  //普通接口
  baseURL: "",
  // baseURL: "/saas",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// // 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// // 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export function post(url, params = {}) {
  const paramsData = {
    encryption: encrypt(params),
  };
  return new Promise((resolve, reject) => {
    instance({
      url: url,
      method: "post",
      data: paramsData,
      headers: {
        Authorization: cookie.getCookie("token"),
      },
    })
      .then((response) => {
        if (response.data.Code == 200) {
          resolve(decrypt(response.data.Data.encryption));
        } else {
          ElMessage.error({
            message: response.data.Message,
          });
          reject(response.data.Message);
          if (response.data.Code == 204) {
            cookie.setCookie("token", "");

            const pathname = window.location.pathname;
            window.location.href = `${pathname}#/login`;
            return;
          }
        }
      })
      .catch((error) => {
        ElMessage.error({
          message: "服务器错误或者网络连接失败",
        });
        reject(error);
      });
  });
}
export function get(url, params = {}) {
  const paramsData = {
    encryption: encrypt(params),
  };
  return new Promise((resolve, reject) => {
    instance({
      url: url,
      method: "get",
      data: paramsData,
      headers: {
        Authorization: cookie.getCookie("token"),
      },
    })
      .then((response) => {
        if (response.data.Code == 200) {
          resolve(decrypt(response.data.Data.encryption));
        } else {
          ElMessage.error({
            message: response.data.Message,
          });
          reject(response.data.Message);
          if (response.data.Code == 204) {
            cookie.setCookie("token", "");
            const pathname = window.location.pathname;
            window.location.href = `${pathname}#/login`;
            return;
          }
        }
      })
      .catch((error) => {
        ElMessage.error({
          message: "服务器错误或者网络连接失败",
        });
        reject(error);
      });
  });
}

/**
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */

export function fileUpload(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance({
      url: url,
      method: "post",
      data: params,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
