import { post } from "./axios.js";
class API {
  // 登录
  static login(data) {
    return post("/saas/login/login", data);
  }
  // 修改密码
  static changePassword(data) {
    return post("/saas/user/updatePassword", data);
  }

  // 模块顶部模块
  static getTopMenu(data) {
    return post("/saas/user/getModule", data);
  }
  // 左侧菜单权限
  static getLeftMenu(data) {
    return post("/saas/user/getMenuList", data);
  }
  // 右侧菜单权限
  static getRightMenu(data) {
    return post("/saas/user/getNavigationList", data);
  }
  // 七牛云 token
  static getQiniuToken(data) {
    return post("/saas/upload/getqiniutoken", data);
  }
  // 用户基本信息
  static getUserInfo(data) {
    return post("/saas/user/accountInfo", data);
  }
  // 组织人员-组织与成员-获取组织架构树形数据
  static getDepTree(data) {
    return post("/saas/department/getDepTree", data);
  }
  // 组织人员-组织与成员-更新用户表 状态启用禁用
  static editUserStatus(data) {
    return post("/saas/user/updateStatus", data);
  }
  // 组织人员-组织与成员-获取性别信息
  static getGenderOptions(data) {
    return post("/saas/user/getGenderEnum", data);
  }
  // 获取组织下级 所有项目信息
  static getProjectByDepartmentSub(data) {
    return post("/saas/department/getProjectByDepartmentSub", data);
  }
  // 获组织详情
  static geDepartmentDetail(data) {
    return post("/saas/department/departmentDetails", data);
  }
  // 获取项目列表
  static getDepartment(data) {
    return post("/saas/department/getDepartment", data);
  }
  // 获取用户列表
  static getUser(data) {
    return post("/saas/user/getUser", data);
  }
  // 新增组织
  static addOrganization(data) {
    return post("/saas/department/storeDepartment", data);
  }
  // 编辑组织
  static editOrganization(data) {
    return post("/saas/department/updateDepartment", data);
  }
  // 新增人员
  static addMember(data) {
    return post("/saas/user/storeUser", data);
  }
  // 编辑人员
  static editMember(data) {
    return post("/saas/user/updateUser", data);
  }
  // 获取角色选择框
  static getRoleOption(data) {
    return post("/saas/user/getRoleApi", data);
  }
  // 获取大区
  static getArea(data) {
    return post("/saas/department/getRegion", data);
  }
  // 获取大区下公司
  static getCompanyByArea(data) {
    return post("/saas/department/getCompany", data);
  }
  // 新增项目
  static addProject(data) {
    return post("/saas/department/storeProject", data);
  }
  // 编辑项目
  static editProject(data) {
    return post("/saas/department/updateProject", data);
  }
  // 获取项目详情
  static getProjectDetail(data) {
    return post("/saas/department/infoProject", data);
  }
  // 获取项目下部门
  static getProjectByCompany(data) {
    return post("/saas/department/getPosition", data);
  }
  // 重置密码
  static resetPassword(data) {
    return post("/saas/user/resetPassword", data);
  }
  // 获取角色列表
  static getRole(data) {
    return post("/saas/user/getRole", data);
  }
  // 新增角色
  static addRole(data) {
    return post("/saas/user/storeRole", data);
  }
  // 获取成员角色
  static getMemberRole(data) {
    return post("/carnonmotor/common/getGroup", data);
  }
  // 编辑角色
  static editRole(data) {
    return post("/saas/user/updateRole", data);
  }
  // 获取车场列表
  static getParking(data) {
    return post("/carnonmotor/carpark/list", data);
  }
  // 新增车场
  static addParking(data) {
    return post("/carnonmotor/carpark/add", data);
  }
  //  获取 客户商户号
  static getCustomerPay(data) {
    return post("/saas/common/getCustomerPay", data);
  }

  // 获取收费模版列表（新增车场-选择模板共用）
  static getChargeTemplateOptions(data) {
    return post("/carnonmotor/carpark/getTemplate", data);
  }
  // 获取车场选择框数据
  static getParkingOptions(data) {
    return post("/carnonmotor/carpark/getCarparkApi", data);
  }
  // 获取可用厂商选择框数据
  static getFactorOptions(data) {
    return post("/carnonmotor/carpark/getFactor", data);
  }
  // 获取项目选择框
  static getProjectOptions(data) {
    return post("/carnonmotor/common/getVillage", data);
  }
  // 获取车场详情
  static getParkingDetail(data) {
    return post("/carnonmotor/carpark/info", data);
  }
  // 激活车场
  static activateParking(data) {
    return post("/carnonmotor/carpark/activate", data);
  }
  // 编辑车场
  static editParking(data) {
    return post("/carnonmotor/carpark/edit", data);
  }
  // 获取收费方式
  static getChargeTypeOptions(data) {
    return post("/carnonmotor/chargetemplate/getchargetype", data);
  }
  // 闸机管理
  static getGateMachine(data) {
    return post("/carnonmotor/gate/getGate", data);
  }
  // 闸机详情
  static getGateMachineDetail(data) {
    return post("/carnonmotor/gate/gateInfo", data);
  }
  // 闸机状态修改
  static editGateMachineStatus(data) {
    return post("/carnonmotor/gate/gateEnable", data);
  }
  // 获取闸机位置
  static getGateLocationOptions(data) {
    return post("/carnonmotor/gate/getGatePosition", data);
  }
  // 获取闸机类型
  static getGateTypeOptions(data) {
    return post("/carnonmotor/gate/getGateType", data);
  }
  // 获取闸机启用状态
  static getGateStatusOptions(data) {
    return post("/carnonmotor/gate/statusEnum", data);
  }
  // 获取闸机是否计费
  static getGateIsChargeOptions(data) {
    return post("/carnonmotor/gate/getChargeEnum", data);
  }
  // 新增闸机
  static addGateMachine(data) {
    return post("/carnonmotor/gate/storeGate", data);
  }
  // 编辑闸机
  static editGateMachine(data) {
    return post("/carnonmotor/gate/updateGate", data);
  }
  // IC卡管理列表
  static getIcCard(data) {
    return post("/carnonmotor/iccard/getIccard", data);
  }
  // IC卡管理列表-详情
  static getIcCardDetail(data) {
    return post("/carnonmotor/iccard/infoIcCard", data);
  }
  // IC卡管理列表-筛选状态
  static getIcCardStatus(data) {
    return post("/carnonmotor/iccard/getIccardStatus", data);
  }

  // 获取收费模板列表
  static getChargeTemplate(data) {
    return post("/carnonmotor/chargetemplate/list", data);
  }
  // 收费模板状态
  static changeChargeTemplateStatus(data) {
    return post("/carnonmotor/chargetemplate/setstatus", data);
  }

  // 获取通行模式选项
  static getTrafficModeOptions(data) {
    return post("/carnonmotor/chargetemplate/getpasstype", data);
  }

  // 新增收费模板
  static addChargeTemplate(data) {
    return post("/carnonmotor/chargetemplate/addchargetemplate", data);
  }
  // 收费模板详情
  static getChargeTemplateDetail(data) {
    return post("/carnonmotor/chargetemplate/infoChargeTemplate", data);
  }
  // 编辑收费模板
  static editChargeTemplate(data) {
    return post("/carnonmotor/chargetemplate/editchargetemplate", data);
  }
  // 获取收费模板关联数据
  static getProjectByChargeTemplate(data) {
    return post("/carnonmotor/chargetemplate/getprojectmap", data);
  }
  // 编辑收费模板关联数据
  static editProjectByChargeTemplate(data) {
    return post("/carnonmotor/chargetemplate/editprojectmap", data);
  }
  // 运营管理-车辆列表
  static getCar(data) {
    return post("/carnonmotor/car/getCarnonmotor", data);
  }
  // 运营管理-车辆详情
  static getCarDetail(data) {
    return post("/carnonmotor/car/getCarnonmotorInfo", data);
  }
  // 运营管理-车辆类型
  static getCarTypeOptions(data) {
    return post("/carnonmotor/car/getCarType", data);
  }
  // 运营管理-车辆列表-新增包月车
  static addMonthlyCar(data) {
    return post("/carnonmotor/car/storeCarnonmotor", data);
  }
  // 运营管理-车辆列表-续费
  static renewMonthlyCar(data) {
    return post("/carnonmotor/car/carNoticeRenewal", data);
  }
  // 运营管理-车辆列表-编辑
  static editMonthlyCar(data) {
    return post("/carnonmotor/car/edit", data);
  }
  // 运营管理-车辆列表-删除
  static deleteMonthlyCar(data) {
    return post("/carnonmotor/car/del", data);
  }
  // 运营管理-临停收费记录
  static getTemporaryRecord(data) {
    return post("/carnonmotor/temporarystop/chargelist", data);
  }
  // 运营管理-临停收费记录-筛选状态
  static getStatusOptions(data) {
    return post("/carnonmotor/temporarystop/getStatus", data);
  }
  // 运营管理-临停收费记录-通行记录
  static getTrafficRecord(data) {
    return post("/carnonmotor/temporarystop/trafficrecord", data);
  }
  // 财务数据-获取支付方式
  static getChargeWayOptions(data) {
    return post("/reim/common/getchargeway", data);
  }
  // 财务数据-获取缴费内容
  static getChargeContentOptions(data) {
    return post("/reim/common/getresourcestype", data);
  }
  // 财务数据-订单管理
  static getOrder(data) {
    return post("/reim/order/list", data);
  }
  // 财务数据-报表
  static getStatement(data) {
    return post("/reim/reportform/list", data);
  }
}
export default API;
