import CryptoJS from "crypto-js";

const key = "OkjU0BZleafTNyKEIr4xjYb0xFOZT2TA";
const iv = "b6cea4cce95ec545";

//加密
export function encrypt(data) {
  const keyBinary = CryptoJS.enc.Latin1.parse(key);
  const ivBinary = CryptoJS.enc.Latin1.parse(iv);
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), keyBinary, {
    iv: ivBinary,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

//解密
export function decrypt(data) {
  const keyBinary = CryptoJS.enc.Latin1.parse(key);
  const ivBinary = CryptoJS.enc.Latin1.parse(iv);
  const decryptData = CryptoJS.AES.decrypt(data, keyBinary, {
    iv: ivBinary,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return (
    JSON.parse(CryptoJS.enc.Utf8.stringify(decryptData).toString()) || data
  );
}
